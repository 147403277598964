import React from "react";
import styles from "./QuickStart.module.css";
import { useUpdateUserHasSeenTutorialMutation } from "../../profile/profileApiSlice";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const QuickStart = ({ onClose }) => {
  const [updateUserHasSeenTutorial] = useUpdateUserHasSeenTutorialMutation();

  const checkUrl = `/img/QuickStart/checkmark.png`;
  const bookIconUrl = `/img/QuickStart/bookIcon.png`;
  const translateIconUrl = `/img/QuickStart/translateIcon.png`;
  const downChevronUrl = `/img/QuickStart/downChevron.png`;

  const handleClose = async () => {
    onClose();
    try {
      // Immediately close the overlay locally

      // Also update user’s “hasSeenTutorial” in the background
      await updateUserHasSeenTutorial({ value: true }).unwrap();
    } catch (err) {
      console.error("error updating user hasSeenTutorial:", err);
      // Even if update fails, we close the modal locally
    }
  };

  return (
    <div className={styles.overlay} onClick={handleClose}>
      {/* 
        Clicking this outer overlay closes the modal,
        but we stop propagation on the inner container 
        so clicks inside won't close it automatically 
      */}
      <div className={styles.container}>
        <div className={styles.content}>
          <h2>Quick Start Guide</h2>

          <ol className={styles.instructions}>
            <li className={styles.step}>
              <div className={styles.stepText}>
                <div className={styles.stepName}>Step 1</div>
                <div className={styles.stepTitle}>
                  Try to understand the sentence
                </div>
                <div className={styles.subText}>
                  See if you can get at least some idea of what is being
                  communicated. It's completely normal to initially feel lost,
                  even as you reach a higher level. Just do your best, and your
                  level will rise.
                </div>
              </div>
            </li>
            <li className={styles.step}>
              <div className={styles.stepText}>
                <div className={styles.stepName}>Step 2</div>
                <div className={styles.stepTitle}>
                  Select colored word fragments
                </div>
                <div className={styles.subText}>
                  Selecting fragments shows their word's meaning. Use the
                  translations to get a better idea of the sentence's message.
                </div>
                <div className={styles.subText}>
                  Many fragments just have a grammatical significance. Step 4
                  will help with those.
                </div>
                <p className={styles.example}>
                  <span className="new stem">pon</span>
                  <span className="new ending1">ér</span>
                  <span className="new ending2">se</span>
                  <span className="new ending3">le</span>
                </p>
                <div className={styles.caption}>
                  <strong>New fragments</strong> – you have not yet encountered
                  these fragments
                </div>
                <p className={styles.example}>
                  <span className="seen stem">pon</span>
                  <span className="seen ending1">ér</span>
                  <span className="seen ending2">se</span>
                  <span className="seen ending3">le</span>
                </p>
                <div className={styles.caption}>
                  <strong>Seen fragments</strong> – you have encountered these
                  fragments before
                </div>
                <p className={styles.example}>
                  <span className="known stem">pon</span>
                  <span className="known ending1">ér</span>
                  <span className="known ending2">se</span>
                  <span className="known ending3">le</span>
                </p>
                <div className={styles.caption}>
                  <strong>Known fragments</strong> – you have marked these
                  fragments as known
                </div>
                <p className={styles.example}>
                  <span className="seen stem">pon</span>
                  <span className="new ending1">ér</span>
                  <span className="known ending2">se</span>
                  <span className="new ending3">le</span>
                </p>
                <div className={styles.caption}>
                  <strong>Mixed fragments</strong> – multiple fragment states
                  often occur in the same word
                </div>
              </div>
            </li>
            <li className={styles.step}>
              <div className={styles.stepText}>
                <div className={styles.stepName}>Step 3</div>
                <div className={styles.stepTitle}>Mark fragments as known</div>
                <div className={styles.subText}>
                  If you feel like you know the significance of the fragment,
                  mark it as known to progress. It's totally acceptable to move
                  on if you don't quite feel like you have it.
                </div>
                <div className={styles.subText}>
                  Use the checkmark or "K" key to mark as known.
                </div>
                <img
                  style={{
                    border: "2px solid var(--SELECTED-FRAGMENT-OUTLINE-COLOR)",
                    borderRadius: ".222rem",
                    height: "50px",
                  }}
                  src={checkUrl}
                  alt="checkmark"
                />
              </div>
            </li>
            <li className={styles.step}>
              <div className={styles.stepText}>
                <div className={styles.stepName}>Step 4</div>
                <div className={styles.stepTitle}>Use the word info</div>
                <div className={styles.subText}>
                  The word info section will tell you all you need to know about
                  the word's role in the sentence, as well as its fragments'
                  meanings.
                </div>
                <div className={styles.subText}>
                  The book icon shows/hides word info.
                </div>
                <img
                  style={{ height: "50px" }}
                  src={bookIconUrl}
                  alt="book icon"
                />
              </div>
            </li>
            <li className={styles.step}>
              <div className={styles.stepText}>
                <div className={styles.stepName}>Step 5</div>
                <div className={styles.stepTitle}>
                  Use the sentence translation
                </div>
                <div className={styles.subText}>
                  To solidify your feel for the sentence, compare it to the
                  translation. Take as much time as you need to. All that
                  matters is comprehension.
                </div>
                <div className={styles.subText}>
                  The translate icon shows/hides the sentence translation.
                </div>
                <img
                  style={{ height: "50px" }}
                  src={translateIconUrl}
                  alt="translate icon"
                />
              </div>
            </li>
            <li className={styles.step}>
              <div className={styles.stepText}>
                <div className={styles.stepName}>Step 6</div>
                <div className={styles.stepTitle}>
                  Navigate to the next sentence
                </div>
                <div className={styles.subText}>
                  When you are ready, navigate to the next sentece using the
                  down arrow at the top of the page
                </div>
                <img
                  style={{ height: "50px" }}
                  src={downChevronUrl}
                  alt="down arrow"
                />
              </div>
            </li>
          </ol>
          <p className={styles.callout}>Have fun!</p>
        </div>
      </div>
    </div>
  );
};

export default QuickStart;
