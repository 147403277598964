import React, { useState, useContext, useRef, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { SentenceContext } from "../../../SentenceContext/SentenceContext";
import { POS_VALUE_DESCRIPTIONS } from "../attributeValueDescriptions";
import toCamelCase from "../../../../../utils/toCamelCase";
import styles from "./MorphologyItem.module.css";

const MorphologyItem = ({
  attributeName,
  attributeColor,
  attributeValue,
  attributeValueColor,
  attributeNameDescription,
  attributeValueDescription,
  selected,
  isTransitioning,
  wordInstanceId,
  fragmentId,
  isOldLayer,
}) => {
  const [oldHeight, setOldHeight] = useState("auto");

  // 1) Build canonical + layer-specific ID
  const canonicalId = useMemo(
    () => `${wordInstanceId}-${fragmentId}-${attributeName}`,
    [wordInstanceId, fragmentId, attributeName]
  );
  const layerSuffix = isOldLayer ? "old" : "new";
  const layerId = `${canonicalId}-${layerSuffix}`;

  // 2) Get/set states from context
  const {
    getItemDescriptionState,
    setItemDescriptionState,
    getItemDescriptionHeight,
    setItemDescriptionHeight,
    transitionTime,
  } = useContext(SentenceContext);

  // 3) Initialize open/height from context
  let wasOpen = getItemDescriptionState(layerId) || false;
  let wasHeight = getItemDescriptionHeight(layerId) || 0;

  if (!isOldLayer) {
    wasOpen = false;
    wasHeight = 0;
  }

  const [showingValueDescription, setShowingValueDescription] =
    useState(wasOpen);
  const [maxHeight, setMaxHeight] = useState(() => {
    return wasOpen ? wasHeight : 0;
  });

  // 4) Refs
  const contentRef = useRef(null);

  // 5) Whenever open changes, measure or collapse
  useEffect(() => {
    if (showingValueDescription) {
      if (contentRef.current) {
        console.log("setting height");
        const fullHeight = contentRef.current.scrollHeight;
        setMaxHeight(fullHeight);
        setItemDescriptionHeight(layerId, fullHeight);
      }
    } else {
      setMaxHeight(0);
    }
    setItemDescriptionState(layerId, showingValueDescription);
  }, [
    showingValueDescription,
    layerId,
    setItemDescriptionState,
    setItemDescriptionHeight,
  ]);

  // Just for debugging
  useEffect(() => {
    console.log("MorphologyItem:", layerId, "maxHeight =", maxHeight);
  }, [maxHeight, layerId]);

  // 6) Toggling
  const handleToggle = () => {
    setShowingValueDescription((prev) => !prev);
  };

  // 7) Display logic
  const capitalizeFirstLetter = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1);

  const isPos = attributeName === "part of speech";
  const valueArray = isPos ? attributeValue : [attributeValue];

  useEffect(() => {
    if (isTransitioning) {
      setTimeout(() => {
        setOldHeight(0);
      }, transitionTime + 500);
      setOldHeight("auto");
    }
  }, [oldHeight, isTransitioning]);

  return (
    <div
      className={styles.container}
      style={{
        height: isOldLayer ? oldHeight : "auto",
        transition: "height 500ms ease-out 500ms",
      }}
    >
      <div
        className={`
          ${styles.descriptionContainer}
          ${selected ? styles.descriptionContainer__selected : ""}
          ${isTransitioning ? styles.transitioning : ""}
        `}
        onClick={handleToggle}
      >
        <div className={styles.itemContainer}>
          <div className={styles.itemInfoContainer}>
            <div>
              <span className={`${attributeColor} ${styles.attributeName}`}>
                {attributeName}:{" "}
              </span>
              <span
                className={`${attributeValueColor} ${styles.attributeValue}`}
              >
                {isPos
                  ? valueArray.map((val, idx) => (
                      <React.Fragment key={idx}>
                        {val}
                        {idx < valueArray.length - 1 ? ", " : ""}
                      </React.Fragment>
                    ))
                  : attributeValue}
              </span>
            </div>
            <FontAwesomeIcon
              className={`${styles.icon} ${
                showingValueDescription ? styles.iconOpen : ""
              }`}
              icon={faChevronDown}
            />
          </div>
        </div>

        <div
          ref={contentRef}
          className={`
            ${styles.descriptions}
            ${
              showingValueDescription
                ? styles.descriptions__show
                : styles.descriptions__hide
            }
          `}
          style={{ maxHeight: `${maxHeight}px` }}
        >
          <p className={`${attributeColor} ${styles.attributeNameDescription}`}>
            {`${capitalizeFirstLetter(
              attributeName
            )} ${attributeNameDescription?.toLowerCase()}.`}
          </p>

          {valueArray.map((val, idx) => (
            <p
              key={idx}
              className={`${attributeValueColor} ${styles.valueDescription}`}
            >
              {isPos ? (
                POS_VALUE_DESCRIPTIONS[toCamelCase(val)] ? (
                  <>{POS_VALUE_DESCRIPTIONS[toCamelCase(val)]}.</>
                ) : (
                  <span className={styles.valueSpan}>{val}</span>
                )
              ) : (
                attributeValueDescription && <>{attributeValueDescription}.</>
              )}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MorphologyItem;
