import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../auth/AuthContext";
import { useSelector } from "react-redux";
import { selectBookById } from "../booksApiSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faLockOpen,
  faFeather,
  faHourglassStart,
} from "@fortawesome/free-solid-svg-icons";
import Sale from "../Sale/Sale";

import styles from "./Lesson.module.css";

const Lesson = ({
  bookId,
  chapter,
  coverSrc,
  progress,
  available,
  free,
  shouldFlash,
}) => {
  const premium = free ? false : true;
  const { isAdmin, sub, createCheckoutSession } = useContext(AuthContext);
  const locked = !isAdmin && premium && !sub;

  // const isAdmin = false;
  // const premium = free ? false : true;
  // const { sub, createCheckoutSession } = useContext(AuthContext);
  // const locked = !isAdmin && premium && !sub;

  const [showSale, setShowSale] = useState(false);

  const handleShowSale = () => {
    setShowSale(true);
  };

  // Disable body scrolling when Sale is open
  useEffect(() => {
    if (showSale) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Clean up on component unmount
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showSale]);

  const book = useSelector((state) => selectBookById(state, bookId));

  const progressNew = Number.isNaN(parseFloat(progress?.[chapter]?.new))
    ? "--"
    : parseFloat(progress?.[chapter]?.new).toFixed(0);

  const progresSeen = Number.isNaN(parseFloat(progress?.[chapter]?.seen))
    ? "--"
    : parseFloat(progress?.[chapter]?.seen).toFixed(0);

  const progressKnown = Number.isNaN(parseFloat(progress?.[chapter]?.known))
    ? "--"
    : parseFloat(progress?.[chapter]?.known).toFixed(0);

  const lessonContents = (
    <li>
      <figure className={styles.figure}>
        <div className={styles.lessonCoverContainer}>
          {available & locked ? (
            <div className={styles.lessonCoverOverlay}>
              <p className={styles.subscribe}>
                <span className={styles.subscribeWrap}>
                  <FontAwesomeIcon
                    className={styles.subscribeIcon}
                    icon={faFeather}
                  />
                  subscribe
                </span>
                {/* {" "}to unlock */}
              </p>
            </div>
          ) : !available ? (
            <div className={styles.lessonCoverOverlay}>
              <p className={styles.comingSoon}>
                <span className={styles.comingSoonWrap}>
                  <FontAwesomeIcon
                    className={styles.comingSoonIcon}
                    icon={faHourglassStart}
                  />
                  coming
                </span>{" "}
                soon
              </p>
            </div>
          ) : (
            <></>
          )}
          <img
            className={styles.lessonCover}
            src={coverSrc}
            alt="Lesson Cover"
            loading="lazy"
          ></img>
        </div>
        <figcaption className={styles.figcaption}>
          <h3 className={`${styles.lessonHeader}`}>
            {book.title}
            {!available || sub || isAdmin ? (
              <></>
            ) : locked ? (
              <FontAwesomeIcon icon={faLock} />
            ) : (
              <FontAwesomeIcon icon={faLockOpen} />
            )}
          </h3>
          <p className={styles.chapterTitle}>Chapter {chapter}</p>
          <p className={`${styles.lessonAuthor} great-vibes-regular`}>
            {book.author}
          </p>
          <div className={styles.figcaption_vocabRow}>
            <img
              className={styles.figcaption_vocabFlag}
              src="/img/flags/Spanish.png"
              alt="Spanish flag"
              loading="lazy"
            ></img>
            {/* <div className={styles.percentagesRow}> */}
            <span
              title="% of New Fragments"
              className={styles.figcaption_vocab__new}
            >
              {progressNew}%
            </span>
            <span
              title="% of Seen Fragments"
              className={styles.figcaption_vocab__seen}
            >
              {progresSeen}%
            </span>
            <span
              title="% of Known Fragments"
              className={styles.figcaption_vocab__known}
            >
              {progressKnown}%
            </span>
            {/* </div> */}
          </div>
        </figcaption>
      </figure>
    </li>
  );

  let content;

  if (available && !locked) {
    content = (
      <div>
        <div
          onClick={handleShowSale}
          className={`${styles.container} ${styles.available}`}
        >
          {lessonContents}
        </div>

        {showSale && (
          <Sale
            onClose={() => setShowSale(false)}
            chapter={chapter}
            bookId={bookId}
            available={available}
            locked={locked}
            progress={progress}
            shouldFlash={shouldFlash}
          />
        )}
      </div>
    );
  } else if (available && locked) {
    content = (
      <div
        onClick={createCheckoutSession}
        className={`${styles.container} ${styles.available}`}
      >
        {lessonContents}
      </div>
    );
  } else if (!available) {
    content = (
      <div className={`${styles.container} ${styles.unavailable}`}>
        {lessonContents}
      </div>
    );
  }

  return content;
};

export default Lesson;
