import React, { useContext, useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useUpdateUserHasLoggedInMutation } from "../../profile/profileApiSlice";
import styles from "./Sale.module.css";
import { selectBookById } from "../booksApiSlice";
import { AuthContext } from "../../auth/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { chapterDescriptions } from "../Sale/chapterDescriptions/chapterDescriptions";

const Sale = ({
  onClose,
  chapter,
  bookId,
  available,
  progress,
  shouldFlash,
}) => {
  const coverLink = `/img/lesson-covers/don-quixote/chapter${chapter}.jpeg`;
  const book = useSelector((state) => selectBookById(state, bookId));
  const { isAdmin, sub, createCheckoutSession } = useContext(AuthContext);

  const [updateUserHasLoggedIn] = useUpdateUserHasLoggedInMutation();

  const handleUpdateUserHasLoggedIn = async () => {
    if (shouldFlash) {
      try {
        await updateUserHasLoggedIn({ value: true }).unwrap();
      } catch (err) {
        console.error("error updating user hasLoggedIn:", err);
      }
    }
  };

  const progressNew = Number.isNaN(parseFloat(progress?.new))
    ? "--"
    : parseFloat(progress?.new).toFixed(0);

  const progresSeen = Number.isNaN(parseFloat(progress?.seen))
    ? "--"
    : parseFloat(progress?.seen).toFixed(0);

  const progressKnown = Number.isNaN(parseFloat(progress?.known))
    ? "--"
    : parseFloat(progress?.known).toFixed(0);

  const descriptions = chapterDescriptions[chapter] || [];

  // Set up a ref for the scrollable container and state for the chevron indicator.
  const scrollRef = useRef(null);
  const [showIndicator, setShowIndicator] = useState(true);

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (!scrollElement) return;

    const handleScroll = () => {
      // Determine if we are at (or nearly at) the bottom.
      const isAtBottom =
        scrollElement.scrollTop + scrollElement.clientHeight >=
        scrollElement.scrollHeight - 1;
      setShowIndicator(!isAtBottom);
    };

    // Initialize the indicator state.
    handleScroll();

    scrollElement.addEventListener("scroll", handleScroll);
    return () => scrollElement.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={styles.overlay} onClick={onClose}>
      {/* Clicking the overlay closes the modal */}
      <div className={styles.container} onClick={(e) => e.stopPropagation()}>
        <div className={styles.scrollContent} ref={scrollRef}>
          <div className={styles.content}>
            <div className={styles.cover}>
              <img
                src={coverLink}
                alt="Chapter Cover"
                style={{
                  width: "35vw",
                  maxWidth: "200px",
                  borderRadius: ".222rem",
                }}
              />
            </div>
            <div className={styles.rightTitle}>
              <figcaption className={styles.figcaption}>
                <h3 className={`${styles.lessonHeader}`}>{book.title}</h3>
                <p className={styles.chapterTitle}>Chapter {chapter}</p>
                <p className={`${styles.lessonAuthor} great-vibes-regular`}>
                  {book.author}
                </p>
                <div className={styles.figcaption_vocabRow}>
                  <span
                    title="% of New Fragments"
                    className={styles.figcaption_vocab__new}
                  >
                    {/* {progressNew}% */}
                  </span>
                  <span
                    title="% of Seen Fragments"
                    className={styles.figcaption_vocab__seen}
                  >
                    {/* {progresSeen}% */}
                  </span>
                  <span
                    title="% of Known Fragments"
                    className={styles.figcaption_vocab__known}
                  >
                    {/* {progressKnown}% */}
                  </span>
                </div>
              </figcaption>
            </div>
            {descriptions.map((desc, index) => (
              <div className={styles.subText} key={index}>
                {desc}
              </div>
            ))}
            <div className={styles.linkContainer}>
              <Link
                onClick={handleUpdateUserHasLoggedIn}
                to={`${available ? `/sv/${bookId}/${chapter}` : ""} `}
                className={styles.available}
              >
                <FontAwesomeIcon icon={faBookOpen} viewBox="0 -48 576 512" />
                read
              </Link>
            </div>
          </div>
        </div>
        {showIndicator && (
          <div className={styles.scrollIndicator}>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Sale;
