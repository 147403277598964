// SentenceContext.js
import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useGetUserProfileQuery,
  useUpdateUserKnownFragmentCountMutation,
  useUpdateLastReadMutation,
} from "../../profile/profileApiSlice";
import {
  useGetUserVocabularyQuery,
  useUpsertUserVocabularyMutation,
} from "../../profile/vocabularyApiSlice";
import { useGetBookByIdQuery } from "../../library/booksApiSlice";
import { useGetAllSentencesInBookQuery } from "../sentencesApiSlice";
import { selectWordInstancesBySentenceId } from "../wordInstancesApiSlice";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { selectSentenceById } from "../sentencesApiSlice";
import scrollToTop from "../../../utils/scrollToTop";

export const SentenceContext = createContext();

export const SentenceProvider = ({ children }) => {
  const { bookId, chapter } = useParams();

  const descriptionStatesRef = useRef({});
  const descriptionHeightsRef = useRef({});

  const getItemDescriptionState = (itemId) => {
    return descriptionStatesRef.current[itemId];
  };

  const setItemDescriptionState = (itemId, isOpen) => {
    descriptionStatesRef.current[itemId] = isOpen;
  };

  const getItemDescriptionHeight = (itemId) => {
    return descriptionHeightsRef.current[itemId] || 0;
  };

  const setItemDescriptionHeight = (itemId, height) => {
    descriptionHeightsRef.current[itemId] = height;
  };

  const [isTransitioning, setIsTransitioning] = useState(false);

  // State
  const [currentSentenceId, setCurrentSentenceId] = useState(null);
  const [selectedWord, setSelectedWord] = useState(null);
  const [selectedFragment, setSelectedFragment] = useState(null);
  const [translationVisible, setTranslationVisible] = useState({});
  const [showTranslationSection, setShowTranslationSection] = useState(false);
  const [showGrammarSection, setShowGrammarSection] = useState(true);
  const [knownCount, setKnownCount] = useState(null);
  const [indexAnimateDirection, setIndexAnimateDirection] = useState(null);

  // Cooldown States
  const [isSelectionCooldown, setIsSelectionCooldown] = useState(false); // Cooldown after selecting a fragment
  const [isToggleCooldown, setIsToggleCooldown] = useState(false); // Cooldown after toggling a fragment

  // Refs for cooldown timeouts
  const selectionCooldownRef = useRef(null);
  const toggleCooldownRef = useRef(null);

  // Redux Queries and Mutations
  const { data: book } = useGetBookByIdQuery(bookId);
  const { data: sentences, isSuccess: sentences_isSuccess } =
    useGetAllSentencesInBookQuery(book ? { bookId, chapter } : skipToken);
  const { data: userProfile } = useGetUserProfileQuery("userProfile");
  const [upsertUserVocabulary] = useUpsertUserVocabularyMutation();
  const [updateUserKnownFragmentCount] =
    useUpdateUserKnownFragmentCountMutation();
  const [updateLastRead] = useUpdateLastReadMutation();
  const { data: vocabularies } = useGetUserVocabularyQuery("userVocabulary");

  const currentSentence = useSelector((state) =>
    selectSentenceById(state, bookId, chapter, currentSentenceId)
  );

  const currentWordInstances = useSelector((state) =>
    selectWordInstancesBySentenceId(state, bookId, chapter, currentSentenceId)
  );

  const [selectedWordInstance, setSelectedWordInstance] = useState(null);
  // useEffect(() => {
  //   setSelectedWordInstance(
  //     currentWordInstances.find((wi) => wi._id === selectedWord)
  //   );
  // }, [currentWordInstances, selectedWord]);

  // Side Effects
  // Scroll to top of target when navigating sentences
  const targetSentenceRef = useRef(null);
  useEffect(() => {
    scrollToTop(targetSentenceRef);
  }, [currentSentenceId]);

  // Initialize known fragment count
  useEffect(() => {
    if (book?.language && knownCount === null) {
      (async () => {
        try {
          const data = await updateUserKnownFragmentCount({
            languageId: book?.language,
          }).unwrap();
          setKnownCount(data.knownCount);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [book?.language, knownCount, updateUserKnownFragmentCount]);

  // Open book to last read sentence
  useEffect(() => {
    if (
      !currentSentenceId &&
      sentences_isSuccess &&
      sentences?.ids?.length &&
      userProfile
    ) {
      if (userProfile.lastRead && userProfile.lastRead[bookId]?.[chapter]) {
        setCurrentSentenceId(userProfile.lastRead[bookId][chapter]);
      } else {
        setCurrentSentenceId(sentences.ids[0]);
      }
    }
  }, [
    sentences,
    sentences_isSuccess,
    currentSentenceId,
    userProfile,
    bookId,
    chapter,
  ]);

  // Save lastRead
  const handleSaveLastRead = async (id) => {
    try {
      await updateLastRead({
        bookId: bookId,
        chapter,
        sentenceId: id,
      }).unwrap();
    } catch (err) {
      console.error("error updating last read:", err);
    }
  };

  // Helpers
  const getVocabularyByFragmentId = (fragmentId) => {
    if (vocabularies) {
      return Object.values(vocabularies.entities).find(
        (vocab) => vocab.fragment === fragmentId
      );
    }
  };

  // Sentence Navigation
  const currentSentenceIndex = sentences?.ids.indexOf(currentSentenceId);
  const totalSentences = sentences?.ids.length;

  const onNextSentenceClick = async () => {
    if (
      currentSentenceIndex !== undefined &&
      currentSentenceIndex >= 0 &&
      currentSentenceIndex < sentences.ids.length - 1
    ) {
      setIndexAnimateDirection("up");
      const nextSentenceId = sentences.ids[currentSentenceIndex + 1];
      setCurrentSentenceId(nextSentenceId);

      // Move resetSelections after setting new sentence ID
      setTimeout(() => {
        setShowTranslationSection(false);
      }, 100);
      await handleSaveLastRead(nextSentenceId);
      setTranslationVisible({});
      resetSelections(); // <-- Move this here
    }
  };

  const onPrevSentenceClick = async () => {
    if (currentSentenceIndex !== undefined && currentSentenceIndex > 0) {
      setIndexAnimateDirection("down");
      const prevSentenceId = sentences.ids[currentSentenceIndex - 1];
      setCurrentSentenceId(prevSentenceId);
      setTimeout(() => {
        setShowTranslationSection(false);
      }, 100);

      // Move resetSelections after setting new sentence ID
      await handleSaveLastRead(prevSentenceId);
      setTranslationVisible({});
      resetSelections(); // <-- Move this here
    }
  };

  const showTranslation = (wordInstanceId) => {
    setTranslationVisible((prev) => ({
      ...prev,
      [wordInstanceId]: true,
    }));
  };

  const transitionTime = 500;

  // Mutation Functions
  const toggleMarkAsKnown = async () => {
    if (selectedFragment) {
      // If there's already an active toggle cooldown, prevent the toggle
      if (isToggleCooldown) {
        return;
      }

      const vocabulary = getVocabularyByFragmentId(selectedFragment);

      // Start the toggle cooldown immediately, before the toggle action
      setIsToggleCooldown(true);
      toggleCooldownRef.current = setTimeout(() => {
        setIsToggleCooldown(false);
        toggleCooldownRef.current = null;
      }, transitionTime);

      // Update the count immediately for better UX
      switch (vocabulary.state) {
        case "known":
          setKnownCount(knownCount - 1);
          break;
        default:
          setKnownCount(knownCount + 1);
          break;
      }

      try {
        const newState = vocabulary?.state === "known" ? "seen" : "known";
        await upsertUserVocabulary({
          vocabularyId: vocabulary?._id,
          fragmentId: selectedFragment,
          newState,
          languageId: book.language,
        }).unwrap();
      } catch (err) {
        // Revert the count if the API call fails
        switch (vocabulary.state) {
          case "known":
            setKnownCount(knownCount + 1);
            break;
          default:
            setKnownCount(knownCount - 1);
            break;
        }
        console.error("error updating vocabulary:", err);
      }
    }
  };

  const markAsSeen = async (wordId, fragmentId, vocabulary) => {
    if (!vocabulary?._id || vocabulary?.state === "new") {
      try {
        await upsertUserVocabulary({
          vocabularyId: vocabulary?._id,
          fragmentId: fragmentId,
          newState: "seen",
          languageId: book.language,
        }).unwrap();
      } catch (err) {
        console.error("Error updating vocabulary:", err);
      }
    }
  };

  const performFragmentSelectionAndToggle = (
    wordId,
    fragmentId,
    vocabulary
  ) => {
    // First perform the selection
    setSelectedFragment(fragmentId);
    setSelectedWord(wordId);
    showTranslation(wordId);
    markAsSeen(wordId, fragmentId, vocabulary);

    if (vocabulary?.state === "known" || vocabulary?.state === "seen") {
      toggleMarkAsKnown();
    }

    setIsSelectionCooldown(true);
    selectionCooldownRef.current = setTimeout(() => {
      setIsSelectionCooldown(false);
      selectionCooldownRef.current = null;
    }, transitionTime); // 1-second cooldown
  };

  const performFragmentSelection = (wordId, fragmentId, vocabulary) => {
    // First perform the selection
    setSelectedFragment(fragmentId);
    setSelectedWord(wordId);
    showTranslation(wordId);
    markAsSeen(wordId, fragmentId, vocabulary);

    // Start selection cooldown
    setIsSelectionCooldown(true);
    selectionCooldownRef.current = setTimeout(() => {
      setIsSelectionCooldown(false);
      selectionCooldownRef.current = null;
    }, transitionTime); // 1-second cooldown
  };

  // Handle Fragment Selection without any restrictions
  const handleFragmentSelection = useCallback(
    (wordId, fragmentId, vocabulary) => {
      // Case 1: Selecting the currently selected fragment
      if (fragmentId === selectedFragment) {
        showTranslation(wordId);
        return;
      }

      // Case 2: Attempting to select a new fragment during cooldown
      if (
        (isSelectionCooldown || isToggleCooldown) &&
        fragmentId !== selectedFragment
      ) {
        return;
      }

      // Case 3: Selecting a new fragment (no cooldown or different fragment)
      const newWordInstance = currentWordInstances.find(
        (wi) => wi._id === wordId
      );

      setSelectedFragment(fragmentId);
      setSelectedWord(wordId);
      setSelectedWordInstance(newWordInstance);
      showTranslation(wordId);
      markAsSeen(wordId, fragmentId, vocabulary);

      // Only start cooldown when selecting a new fragment
      if (fragmentId !== selectedFragment) {
        setIsSelectionCooldown(true);
        selectionCooldownRef.current = setTimeout(() => {
          setIsSelectionCooldown(false);
          selectionCooldownRef.current = null;
        }, transitionTime);
      }
    },
    [
      isSelectionCooldown,
      isToggleCooldown,
      selectedFragment,
      currentWordInstances,
    ]
  );

  // Sentence Navigation Continued
  const onNextFragmentClick = (currentWordInstances) => {
    let vocabulary;

    // Check no selection
    if (!selectedWord || !selectedFragment) {
      vocabulary = getVocabularyByFragmentId(
        currentWordInstances[0]?.stem?._id
      );
      // Select first word stem
      handleFragmentSelection(
        currentWordInstances[0]?._id,
        currentWordInstances[0]?.stem?._id,
        vocabulary
      );
      return;
    }

    // Check selection
    const currentWord = currentWordInstances.find(
      (wi) => wi._id === selectedWord
    );
    if (!currentWord) return;

    // Get word index
    const selectedWordIdx = currentWordInstances.findIndex(
      (wi) => wi._id === selectedWord
    );

    // Get fragment index
    const fragments = ["stem", "ending1", "ending2", "ending3"]
      .map((fragment) => currentWord[fragment]?._id)
      .filter((id) => id);
    const selectedFragmentIdx = fragments.indexOf(selectedFragment);

    // Check if additional fragments
    if (
      selectedFragmentIdx >= 0 &&
      selectedFragmentIdx < fragments.length - 1
    ) {
      vocabulary = getVocabularyByFragmentId(
        fragments[selectedFragmentIdx + 1]
      );
      // Select next fragment
      handleFragmentSelection(
        selectedWord,
        fragments[selectedFragmentIdx + 1], // next fragment
        vocabulary
      );
      return;
    }

    // Check if additional words
    else if (selectedWordIdx < currentWordInstances.length - 1) {
      vocabulary = getVocabularyByFragmentId(
        currentWordInstances[selectedWordIdx + 1].stem._id
      );
      // Select next word stem
      handleFragmentSelection(
        currentWordInstances[selectedWordIdx + 1]._id, // next word
        currentWordInstances[selectedWordIdx + 1].stem._id, // stem
        vocabulary
      );
    }

    // If last word, call onNextSentenceClick
    else {
      onNextSentenceClick();
    }
  };

  const onPrevFragmentClick = (currentWordInstances) => {
    let vocabulary;

    // Check no selection
    if (!selectedWord || !selectedFragment) {
      // Check prev sentence
      if (currentSentenceIndex > 0) {
        // Go to prev sentence
        onPrevSentenceClick();
      }
      return;
    }

    // Check selection
    const currentWord = currentWordInstances.find(
      (wi) => wi._id === selectedWord
    );
    if (!currentWord) return;

    // Get word index
    const selectedWordIdx = currentWordInstances.findIndex(
      (wi) => wi._id === selectedWord
    );

    // Get fragment index
    const fragments = ["stem", "ending1", "ending2", "ending3"]
      .map((fragment) => currentWord[fragment]?._id)
      .filter((id) => id);
    const selectedFragmentIdx = fragments.indexOf(selectedFragment);

    // Check if prev fragments
    if (selectedFragmentIdx > 0) {
      vocabulary = getVocabularyByFragmentId(
        fragments[selectedFragmentIdx - 1]
      );
      // Select prev fragment
      handleFragmentSelection(
        selectedWord,
        fragments[selectedFragmentIdx - 1], // prev fragment
        vocabulary
      );
      return;
    }

    // Check if prev words
    else if (selectedWordIdx > 0) {
      // Get prev word
      const prevWord = currentWordInstances[selectedWordIdx - 1];
      // Get prev word fragments
      const prevWordFragments = ["stem", "ending1", "ending2", "ending3"]
        .map((fragment) => prevWord[fragment]?._id)
        .filter((id) => id);
      // Get prev word last ending
      const prevWordLastFragment =
        prevWordFragments[prevWordFragments.length - 1];

      vocabulary = getVocabularyByFragmentId(prevWordLastFragment);
      // Select prev word last ending
      handleFragmentSelection(
        currentWordInstances[selectedWordIdx - 1]._id, // prev word
        prevWordLastFragment,
        vocabulary
      );
    }

    // If first word, call onPrevSentenceClick
    else {
      onPrevSentenceClick();
    }
  };

  const toggleTranslationVisibility = () => {
    setTranslationVisible((prev) => ({
      ...prev,
      [selectedWordInstance._id]: !prev[selectedWordInstance._id],
    }));
  };

  const handleSeeTranslationClick = () => {
    toggleTranslationVisibility();
  };

  const resetSelections = () => {
    setSelectedWord(null);
    setSelectedFragment(null);
  };

  // Known Count Animations
  const [knownCountUp, setKnownCountUp] = useState(false);
  const [knownCountDown, setKnownCountDown] = useState(false);
  const [prevCount, setPrevCount] = useState(knownCount);

  useEffect(() => {
    if (knownCount > prevCount) {
      setKnownCountUp(true);
      const timer = setTimeout(() => setKnownCountUp(false), transitionTime);
      return () => clearTimeout(timer);
    } else {
      setKnownCountUp(false);
    }
    setPrevCount(knownCount);
  }, [knownCount]);

  useEffect(() => {
    if (knownCount < prevCount) {
      setKnownCountDown(true);
      const timer = setTimeout(() => setKnownCountDown(false), transitionTime);
      return () => clearTimeout(timer);
    } else {
      setKnownCountDown(false);
    }
    setPrevCount(knownCount);
  }, [knownCount]);

  // Cleanup cooldown timeouts on unmount
  useEffect(() => {
    return () => {
      if (selectionCooldownRef.current) {
        clearTimeout(selectionCooldownRef.current);
      }
      if (toggleCooldownRef.current) {
        clearTimeout(toggleCooldownRef.current);
      }
    };
  }, []);

  return (
    <SentenceContext.Provider
      value={{
        bookId,
        chapter,

        currentSentence,
        currentWordInstances,

        userProfile,

        bookTitle: book.title,
        knownCount,
        knownCountUp,
        knownCountDown,

        targetSentenceRef,

        currentSentenceId,
        currentSentenceIndex,
        beginsParagraph:
          sentences?.entities[currentSentenceId]?.beginsParagraph || false,
        totalSentences,
        indexAnimateDirection,

        selectedWord,
        selectedWordInstance,
        selectedFragment,
        translationVisible,

        showTranslationSection,
        setShowTranslationSection,
        showGrammarSection,
        setShowGrammarSection,
        getItemDescriptionState,
        setItemDescriptionState,
        getItemDescriptionHeight,
        setItemDescriptionHeight,
        isTransitioning,
        setIsTransitioning,

        getVocabularyByFragmentId,
        handleFragmentSelection, // Selection handler without cooldown
        onNextFragmentClick,
        onPrevFragmentClick,
        onNextSentenceClick,
        onPrevSentenceClick,
        handleSeeTranslationClick,
        toggleMarkAsKnown,
        isSelectionCooldown, // For selection cooldown UI feedback
        isToggleCooldown, // For toggle cooldown UI feedback
        transitionTime,
      }}
    >
      {children}
    </SentenceContext.Provider>
  );
};
