import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import { ATTRIBUTE_LIST } from "./attributeList";
import MorphologyItem from "./MorphologyItem/MorphologyItem";
import { SentenceContext } from "../../SentenceContext/SentenceContext";
import styles from "./MorphologyContent.module.css";

// These are your existing fade constants:
const FADE_OUT_DURATION = 500;
const FADE_IN_DELAY = 1000;
const STAGGER_DIVISOR = 1.0;

const MorphologyContent = () => {
  const [latestColors, setLatestColors] = useState({});
  const [latestValueColors, setLatestValueColors] = useState({});
  const [newSelectedStates, setNewSelectedStates] = useState({});
  const [isGrammarOpening, setIsGrammarOpening] = useState(false);
  const [isSentenceChange, setIsSentenceChange] = useState(false);

  const currentAttributesRef = useRef(null);
  const currentSelectedStatesRef = useRef({});

  const {
    showGrammarSection,
    selectedWordInstance,
    getVocabularyByFragmentId,
    selectedFragment,
    selectedWord,
    currentSentenceId,
    getItemDescriptionState,
    setItemDescriptionState,
    getItemDescriptionHeight,
    setItemDescriptionHeight,
    isTransitioning,
    setIsTransitioning,
    transitionTime,
  } = useContext(SentenceContext);

  const [oldAttributes, setOldAttributes] = useState([]);
  const [newAttributes, setNewAttributes] = useState([]);
  const [transitionKey, setTransitionKey] = useState(0);

  const [showOld, setShowOld] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [displayedWord, setDisplayedWord] = useState(null);

  const prevShowGrammarRef = useRef(showGrammarSection);
  const prevSentenceRef = useRef(currentSentenceId);
  const prevFragmentRef = useRef(selectedFragment);
  const isAnimatingRef = useRef(false);
  const timeoutRef = useRef([]);

  // ─────────────────────────────────────────────────────────────────────────────
  // 1) Build the current attribute list from the user's logic
  // ─────────────────────────────────────────────────────────────────────────────
  const currentAttributes = useMemo(() => {
    const attrs = ATTRIBUTE_LIST(
      selectedWordInstance,
      getVocabularyByFragmentId,
      selectedFragment
    );
    return Array.isArray(attrs) ? attrs : [];
  }, [selectedWordInstance, getVocabularyByFragmentId, selectedFragment]);

  // ─────────────────────────────────────────────────────────────────────────────
  // 2) Clear all timeouts helper
  // ─────────────────────────────────────────────────────────────────────────────
  const clearAllTimeouts = useCallback(() => {
    timeoutRef.current.forEach(clearTimeout);
    timeoutRef.current = [];
  }, []);

  // ─────────────────────────────────────────────────────────────────────────────
  // 3) Sync colors/selected states whenever currentAttributes changes
  // ─────────────────────────────────────────────────────────────────────────────
  useEffect(() => {
    const newColors = {};
    const newValueColors = {};
    const newSelected = {};

    currentAttributes.forEach((attr) => {
      newColors[attr.attributeName] = attr.attributeColor;
      newValueColors[attr.attributeName] = attr.attributeValueColor;
      newSelected[attr.attributeName] = attr.selected;
    });

    setLatestColors(newColors);
    setLatestValueColors(newValueColors);

    setNewAttributes(currentAttributes);

    if (!isAnimatingRef.current) {
      setNewSelectedStates(newSelected);
      currentSelectedStatesRef.current = newSelected;
    }
  }, [currentAttributes]);

  // ─────────────────────────────────────────────────────────────────────────────
  // 4) copyHeightsFromNewToOld:
  //    This function reads open/height from the -new items
  //    and writes them into the matching -old items.
  // ─────────────────────────────────────────────────────────────────────────────
  const copyHeightsFromNewToOld = useCallback(
    (attributesArray) => {
      attributesArray.forEach((item) => {
        // We must build the same canonicalId as in MorphologyItem:
        // wordInstanceId - fragmentId - attributeName
        // Then append '-new' vs. '-old'.
        const canonicalId = `${selectedWordInstance?.id || "??"}-${
          selectedFragment || "??"
        }-${item.attributeName}`;
        const newId = `${canonicalId}-new`;
        const oldId = `${canonicalId}-old`;

        const wasOpen = getItemDescriptionState(newId);
        const wasHeight = getItemDescriptionHeight(newId);

        setItemDescriptionState(oldId, wasOpen);
        setItemDescriptionHeight(oldId, wasHeight);
      });
    },
    [
      selectedWordInstance,
      selectedFragment,
      getItemDescriptionState,
      getItemDescriptionHeight,
      setItemDescriptionState,
      setItemDescriptionHeight,
    ]
  );

  // ─────────────────────────────────────────────────────────────────────────────
  // 5) runUpdate: triggers the fade sequence from old -> new
  // ─────────────────────────────────────────────────────────────────────────────
  const runUpdate = useCallback(
    (incomingAttributes) => {
      clearAllTimeouts();
      isAnimatingRef.current = true;
      setIsTransitioning(true);

      // STEP A: The old layer is about to become visible, while the new layer
      //        is about to become transparent. So we copy the new’s maxHeights:
      copyHeightsFromNewToOld(currentAttributesRef.current || []);

      // STEP B: Make the old layer hold the current attributes, then show it & hide new
      setOldAttributes(currentAttributesRef.current);
      setShowOld(true);
      setShowNew(false);

      // Start fade-out of old items after a short delay
      const fadeOutTimeout = setTimeout(() => {
        setShowOld(false);
      }, 50);

      // Then show new items (the incomingAttributes) after fade-out finishes
      const transitionTimeout = setTimeout(() => {
        setShowNew(true);
        setIsTransitioning(false);
        isAnimatingRef.current = false;
        currentAttributesRef.current = incomingAttributes;
      }, FADE_IN_DELAY);

      setTransitionKey((prev) => prev + 1);
      timeoutRef.current = [fadeOutTimeout, transitionTimeout];
    },
    [clearAllTimeouts, copyHeightsFromNewToOld, setIsTransitioning]
  );

  // ─────────────────────────────────────────────────────────────────────────────
  // 6) Watch old/new arrays for debugging
  // ─────────────────────────────────────────────────────────────────────────────
  useEffect(() => {
    // console.log("Updated oldAttributes:", oldAttributes);
    // console.log("Updated newAttributes:", newAttributes);
  }, [oldAttributes, newAttributes]);

  // ─────────────────────────────────────────────────────────────────────────────
  // 7) Grammar section toggle logic
  // ─────────────────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (
      showGrammarSection !== prevShowGrammarRef.current &&
      !isAnimatingRef.current
    ) {
      if (showGrammarSection && !prevShowGrammarRef.current) {
        setIsGrammarOpening(true);
        const timeout = setTimeout(() => {
          setIsGrammarOpening(false);
        }, transitionTime);
        timeoutRef.current.push(timeout);
      }
      prevShowGrammarRef.current = showGrammarSection;
    }
  }, [showGrammarSection]);

  // ─────────────────────────────────────────────────────────────────────────────
  // 8) Handle sentence changes
  // ─────────────────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (currentSentenceId !== prevSentenceRef.current) {
      setIsSentenceChange(true);
      currentAttributesRef.current = newAttributes;
      prevSentenceRef.current = currentSentenceId;
      setShowOld(true);
      runUpdate(currentAttributes);
      setTimeout(() => {
        setIsSentenceChange(false);
      }, transitionTime + 1000);
    }
  }, [currentSentenceId, runUpdate, currentAttributes, newAttributes]);

  // ─────────────────────────────────────────────────────────────────────────────
  // 9) Handle word instance changes
  // ─────────────────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (
      !isAnimatingRef.current &&
      selectedWordInstance?.id !== displayedWord?.id
    ) {
      setDisplayedWord(selectedWordInstance);
      currentAttributesRef.current = newAttributes;
      runUpdate(currentAttributes);
    }
  }, [
    selectedWordInstance,
    currentAttributes,
    runUpdate,
    displayedWord,
    newAttributes,
  ]);

  // ─────────────────────────────────────────────────────────────────────────────
  // 10) Initial mount
  // ─────────────────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (newAttributes.length === 0 && currentAttributes.length > 0) {
      console.log("initial mount");
      setDisplayedWord(selectedWordInstance);
      runUpdate(currentAttributes);
    }
  }, [currentAttributes, newAttributes, selectedWordInstance, runUpdate]);

  // ─────────────────────────────────────────────────────────────────────────────
  // 11) Fragment changes (if same word)
  // ─────────────────────────────────────────────────────────────────────────────
  useEffect(() => {
    const isFragmentChange = selectedFragment !== prevFragmentRef.current;
    const isSameWord = selectedWordInstance?.id === displayedWord?.id;

    if (isFragmentChange && isSameWord && !isTransitioning) {
      console.log("fragment change");
      currentAttributesRef.current = currentAttributes;
      prevFragmentRef.current = selectedFragment;

      // Show new instantly
      setNewAttributes(currentAttributes);
      setShowNew(true);
    }
  }, [
    selectedFragment,
    currentAttributes,
    selectedWordInstance,
    displayedWord,
    isTransitioning,
    currentSentenceId,
  ]);

  // ─────────────────────────────────────────────────────────────────────────────
  // 12) Cleanup on unmount
  // ─────────────────────────────────────────────────────────────────────────────
  useEffect(() => clearAllTimeouts, [clearAllTimeouts]);

  // Final arrays for rendering
  const finalOldAttributes = oldAttributes;
  const finalNewAttributes = Array.isArray(newAttributes) ? newAttributes : [];
  const isStagger = !isSentenceChange
    ? showGrammarSection
      ? true
      : false
    : false;

  // Helper for staggered transitions (not crucial to the logic)
  const getTransitionDelay = (index, totalItems) => {
    const itemsCount = totalItems || 1;
    const staggerDelay = 100;
    if (isStagger) {
      return `${index * staggerDelay}ms`;
    } else {
      return "0ms";
    }
  };

  return (
    <div className={styles.container}>
      {/* OLD LAYER */}
      <div className={styles.oldLayer}>
        {finalOldAttributes.map((item, index) => (
          <div
            key={`old-${transitionKey}-${item.attributeName}-${index}`}
            className={`
              ${styles.staggerItem} 
              ${
                showGrammarSection && showOld
                  ? styles.oldShow
                  : isSentenceChange
                  ? styles.newHide
                  : styles.oldHide
              }
            `}
            style={{
              transitionDelay: getTransitionDelay(
                index,
                finalOldAttributes.length
              ),
            }}
          >
            <MorphologyItem
              attributeName={item.attributeName}
              attributeColor={item.attributeColor}
              attributeValue={item.attributeValue}
              attributeValueColor={item.attributeValueColor}
              attributeNameDescription={item.attributeNameDescription}
              attributeValueDescription={item.attributeValueDescription}
              selected={item.selected}
              isTransitioning={isTransitioning}
              wordInstanceId={`${selectedWordInstance?.id}`}
              fragmentId={selectedFragment}
              isOldLayer={true}
            />
          </div>
        ))}
      </div>

      {/* NEW LAYER */}
      <div className={styles.newLayer}>
        {finalNewAttributes.map((item, index) => (
          <div
            key={`new-${transitionKey}-${item.attributeName}-${index}`}
            className={`
              ${styles.staggerItem} 
              ${
                showNew
                  ? isGrammarOpening
                    ? styles.newShowDelayed
                    : showGrammarSection
                    ? styles.newShow
                    : styles.newHide
                  : styles.newHide
              }
            `}
            style={{
              transitionDelay: getTransitionDelay(
                index,
                finalNewAttributes.length
              ),
            }}
          >
            <MorphologyItem
              attributeName={item.attributeName}
              attributeColor={
                latestColors[item.attributeName] || item.attributeColor
              }
              attributeValue={item.attributeValue}
              attributeValueColor={
                latestValueColors[item.attributeName] ||
                item.attributeValueColor
              }
              attributeNameDescription={item.attributeNameDescription}
              attributeValueDescription={item.attributeValueDescription}
              selected={newSelectedStates[item.attributeName]}
              isTransitioning={isTransitioning}
              wordInstanceId={`${selectedWordInstance?.id}`}
              fragmentId={selectedFragment}
              isOldLayer={false}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(MorphologyContent);
