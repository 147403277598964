import React, { useEffect, useContext, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../../components/shared/LogoV2/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faInfoCircle,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

import { SentenceContext } from "../SentenceContext/SentenceContext";
import FlipCounter from "../../../components/FlipCounter/FlipCounter";
import QuickStart from "../QuickStart/QuickStart";

import styles from "./Nav.module.css";

const Nav = ({
  setShowingSSVSettings,
  setShowingSSVHelp,
  handleShowSSVSettingsClick,
  handleShowSSVHelpClick,
  hasSeenTutorial, // passed in from parent or Redux
}) => {
  const navigate = useNavigate();
  const [isAnimating, setIsAnimating] = useState(false);
  const isFirstRender = useRef(true);

  const [showQuickStart, setShowQuickStart] = useState(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return !hasSeenTutorial;
    }
    return false;
  });

  useEffect(() => {
    console.log("showQuickStart", showQuickStart);
  }, [showQuickStart]);

  const {
    userProfile,
    knownCount,
    bookTitle,
    currentSentence,
    totalSentences,
    currentSentenceIndex,
    onNextSentenceClick,
    onPrevSentenceClick,
    indexAnimateDirection,
    transitionTime,
  } = useContext(SentenceContext);

  const shouldFlash = !userProfile?.hasLoggedIn;

  const handlePrevClick = () => {
    if (isAnimating || currentSentenceIndex + 1 <= 1) return;
    setIsAnimating(true);
    onPrevSentenceClick();
    setTimeout(() => {
      setIsAnimating(false);
    }, transitionTime + 1000); // Animation duration + slight buffer
  };

  const handleNextClick = () => {
    if (isAnimating || currentSentenceIndex + 1 >= totalSentences) return;
    setIsAnimating(true);
    onNextSentenceClick();
    setTimeout(() => {
      setIsAnimating(false);
    }, transitionTime + 1000); // Animation duration + slight buffer
  };

  // "p" key -> previous sentence
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "p") {
        handlePrevClick();
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handlePrevClick]);

  // "n" key -> next sentence
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "n") {
        handleNextClick();
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleNextClick]);

  const handleExitClick = async () => {
    setShowingSSVSettings(false);
    setShowingSSVHelp(false);
    navigate("/library");
  };

  const handleShowHelp = () => {
    setShowQuickStart(true);
  };

  return (
    <>
      <div
        className={styles.logo}
        onClick={handleExitClick}
        title="Exit to Library"
      >
        <Logo />
      </div>

      <div className={styles.locationContainer}>
        <FontAwesomeIcon
          className={
            currentSentenceIndex + 1 > 1
              ? `${styles.icon}`
              : `${styles.icon__disabled}`
          }
          icon={faChevronUp}
          onClick={handlePrevClick}
          title='Go to Previous Sentence - "P" Key'
        />
        <div className={styles.locationText}>
          <h2 className={styles.bookTitle}>{bookTitle}</h2>
          <h3 className={styles.chapterTitle}>
            {currentSentence?.chapterTitle
              ? currentSentence?.chapterTitle
              : "Capítulo Primero"}
          </h3>

          <p className={styles.sentencePosition}>
            <span className={styles.text}>sentence</span>
            <FlipCounter
              number={currentSentenceIndex + 1}
              animate={indexAnimateDirection}
              onAnimationComplete={() => setIsAnimating(false)}
            />
            <span className={`${styles.text} ${styles.slash}`}>/</span>
            <span className={styles.text}>{totalSentences}</span>
          </p>
        </div>
        <FontAwesomeIcon
          className={
            currentSentenceIndex + 1 < totalSentences
              ? `${styles.icon}`
              : `${styles.icon__disabled}`
          }
          icon={faChevronDown}
          onClick={handleNextClick}
          title='Go to Next Sentence - "N" Key'
        />
      </div>

      <div className={styles.header2__columnContainer}>
        <div className={styles.header2__iconRow}>
          <FontAwesomeIcon
            className={`${styles.icon__info} ${
              shouldFlash ? "flash-animation" : ""
            }`}
            icon={faInfoCircle}
            title="Help"
            onClick={handleShowHelp}
          />
        </div>
      </div>

      {showQuickStart && (
        <QuickStart onClose={() => setShowQuickStart(false)} />
      )}
    </>
  );
};

export default Nav;
